
import "styles/globals.css"
// import { SessionProvider } from "next-auth/react"
import Script from "next/script";

export default function App({ Component, pageProps: { session, ...pageProps } }) {
  return (
    <>
      <Script src={'https://www.googletagmanager.com/gtag/js?id=G-K7PN1Q72RH'} strategy="afterInteractive"/>
      <Script id='google-analytics' strategy="afterInteractive">
        {
          ` window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-K7PN1Q72RH');`
        }
      </Script>
      <Script strategy='afterInteractive' id='google-tag-manager' dangerouslySetInnerHTML={{ __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-58WX4B3');`}}/>
      <noscript dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-58WX4B3" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}}/>
      <Component {...pageProps} />
      {/*{Component.auth ? (*/}
      {/*  <Auth>*/}
      {/*    <Component {...pageProps} />*/}
      {/*  </Auth>*/}
      {/*) : (*/}
      {/*  <Component {...pageProps} />*/}
      {/*)}*/}
    </>
  )
}

/***
 * Function to protect routes
 * Example: Component.auth = true; (This route is only for authenticated users)
 * @param children {JSX.Element|JSX.Element[]}
 * @returns {JSX.Element|*}
 * @constructor
 */
// function Auth({ children }) {
//   const router = useRouter();
//   const { data: session, status } = useSession();
//   const isUser = !!session?.user;
//   useEffect(() => {
//     if (status === "loading") return;
//     if (!isUser) {
//       // Redirect to login
//       router.push(children.type.auth.redirect || '/auth/login');
//     }
//   }, [isUser, status]);
//
//   if (isUser) {
//     return children;
//   }
//
//   // Session is being fetched, or no user.
//   // If no user, useEffect() will redirect.
//   return <div>Cargando...</div>
// }
